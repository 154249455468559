import http from "@/http-common"
import type { AxiosProgressEvent } from "axios"

import type { MediaPayload } from "@/types/Media.types"
import { useAppStore } from "@/stores/app"
import { useAlertStore } from "@/stores/alert"
import { useAuthenticationStore } from "@/stores/authentication"
import { AlertType } from "@/static/Alert"
import { i18n } from "@/i18n"

class MediaDataService {
  appStore: any
  alertStore: any
  authStore: any

  /**
   * Upload media to etablishment's album
   * @returns
   */
  uploadMedia(payload: MediaPayload, headers: {}): Promise<void> {
    const body = new FormData()
    body.append("establishment_id", String(payload.establishment_id))
    body.append("album", payload.album)
    body.append("file", payload.file)

    return this.upMedia(body, payload.file.name, headers, `/auth/medias/upload`)
  }

  upMedia(body: FormData, filename: string, headers: {}, endpoint: string): Promise<void> {
    this.initStores()

    const config = {
      headers,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        this.appStore.setMediaProgress(
          progressEvent.total ? Math.round((progressEvent.loaded * 100) / progressEvent.total) : 0
        )
      },
    }

    this.appStore.setMediaLoading(true)

    return http.post(endpoint, body, config).then(
      (res) => {
        // this.appStore.setMediaLoading(false)
        if (!res.data.success) {
          switch (res.data.code) {
            case "WRONG_SIZE":
              this.alertStore.push(AlertType.error, i18n.global.t("common.errors.media_too_large"), filename)
              break
            case "EMPTY_FILE":
              this.alertStore.push(AlertType.error, i18n.global.t("common.errors.damaged_file"))
              break
            default:
              this.alertStore.push(AlertType.error, i18n.global.t("common.errors.default"))
              break
          }
        } else {
          return res
        }
        // this.appStore.setMediaProgress(0)
      },
      (e) => {
        switch (e.response.status) {
          case 401:
            break
          default:
            this.alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
            break
        }
      }
    )
  }

  deleteMedia(mediaId: number): Promise<void> {
    return http.delete(`/auth/medias/${mediaId}`)
  }

  updateMediaRank(mediaId: number): Promise<void> {
    return http.put(`/auth/medias/first-rank/${mediaId}`)
  }

  getCategoryAlbumsRules(categoryId: number): Promise<void> {
    return http.get(`/auth/establishments/albums/${categoryId}`)
  }

  initStores() {
    this.appStore = useAppStore()
    this.alertStore = useAlertStore()
    this.authStore = useAuthenticationStore()
  }
}

export default new MediaDataService()
